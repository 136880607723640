import { useEffect, useMemo } from 'react';

import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext';
import { useZustandAccommodationContext } from '@vakantiesnl/services/src/stores/accommodationStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { Search, Theme } from '@vakantiesnl/types';

import { AccommodationRequestBody, mapAccoOnlyOffer } from './utils';

function getCheapestOfferPerDayQueryKey(args: AccommodationRequestBody, entityId?: number): string[] {
	return [`accommodation-cheapest-offers-${entityId}`, JSON.stringify(args)];
}

export function useGetCheapestOfferPerDay(entityId?: number): {
	cheapestOfferPerDayData: Search.AccoOnlyOffersResponseModel | undefined;
	selectedOffer: Search.AccommodationOnlyOffer | undefined;
	isLoadingCheapestOfferPerDay: boolean;
} {
	const {
		state: tripFiltersState,
		computed: { durations },
	} = useTripFilterContext();
	const setIsOfferLoading = useZustandAccommodationContext((s) => s.setIsOfferLoading);

	/** Selected date is first the selected price bar date else it's the selected departure date from the calendar */
	const selectedOfferDate = useMemo(() => {
		if (tripFiltersState.selected.date) return tripFiltersState.selected.date;
		else if (tripFiltersState.departureDate) return tripFiltersState.departureDate[0];
	}, [tripFiltersState.departureDate, tripFiltersState.selected.date]);

	const requestBody: AccommodationRequestBody = {
		pre_offset: 15,
		post_offset: 16,
		brand: Theme.zvrnl,
		...(tripFiltersState.departureDate ? { departure_date: tripFiltersState.departureDate[0] } : {}),
		durations,
		party: tripFiltersState.party[0],
	};

	const query = useQuery({
		queryKey: getCheapestOfferPerDayQueryKey(requestBody, entityId),
		staleTime: getMinutesInMs(15),
		queryFn: () => fetchGetCheapestOfferPerDay(requestBody, entityId),
		placeholderData: keepPreviousData,
		enabled: !!entityId,
	});

	useEffect(() => {
		if (query.isFetching) {
			setIsOfferLoading(true);
		}
	}, [query.isFetching, setIsOfferLoading]);

	/**
	 * Selected offer
	 * @when a selected date is provided, find an offer with the date
	 * or match the cheapest offer
	 */
	const selectedOffer = selectedOfferDate
		? query.data?.accommodations.offers.find(
				({ departureDateYYYYMMDD }) => departureDateYYYYMMDD === selectedOfferDate,
			)
		: query.data?.cheapestOffer;

	return {
		cheapestOfferPerDayData: query.data,
		selectedOffer,
		isLoadingCheapestOfferPerDay: query.isFetching,
	};
}

const createGetCheapestOfferPerDay = (body: AccommodationRequestBody, entityId: number): RequestInfo => {
	return new Request(
		`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${entityId}/acco-only/cheapest_offer_per_day`,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body),
		},
	);
};

async function fetchGetCheapestOfferPerDay(
	body: AccommodationRequestBody,
	entityId?: number,
): Promise<Search.AccoOnlyOffersResponseModel | undefined> {
	if (!entityId) return undefined;

	const rawCheapestOfferPerDay = await fetchHandler<Search.RawAccoOnlyOffersResponseModel>({
		fetchFn: () => fetchVakNL({ input: createGetCheapestOfferPerDay(body, entityId) }),
		errorMessage: `Something went wrong during fetching cheapest offers per day for entityId ${entityId}`,
	});

	const mappedOffers = rawCheapestOfferPerDay.accommodations[0].offers?.map(mapAccoOnlyOffer);

	const prices = mappedOffers.map(({ priceTotal }) => priceTotal);
	const [minPrice, maxPrice] = [Math.min(...prices), Math.max(...prices)];

	/** Select the lowest price, as a fall back (should never happen but ts asked me to) use the first offer */
	const cheapestOffer = mappedOffers.find(({ priceTotal }) => minPrice === priceTotal) ?? mappedOffers[0];

	return {
		party: rawCheapestOfferPerDay.party,
		brand: rawCheapestOfferPerDay.brand,
		currency: rawCheapestOfferPerDay.currency,
		resultsTotal: rawCheapestOfferPerDay.results_total,
		accommodations: {
			offers: mappedOffers,
		},
		minPrice,
		maxPrice,
		cheapestOffer,
	};
}
